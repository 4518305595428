/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { compose } from "redux";
import { connectAutoDispatch } from "redux/reduxConnect";
import { getCurrentPluginsAddedRequest } from "redux/actions/PlanActions";
import { planInitState, planPropTypes } from "redux/reducers/PlanReducer";
import { CustomPricingTable, AzApiWrapper } from "../../../plans/plan.style";
import type { TableColumnsType } from "antd";
import { Badge, Button, Card, Flex, Space, Table, Typography } from "antd";
import { format } from "../../../../../utils";

interface DataType {
  key: React.Key;
  title: string;
}

interface PriceDataType {
  key: React.Key;
  from: string;
  to: number;
  price: string;
}

const ASINZEN_API_YEARLY: TableColumnsType<PriceDataType> = [
  {
    key: "yearly-50",
    from: 1,
    to: 20,
    price: 50
  },
  {
    key: "yearly-45",
    from: 21,
    to: 100,
    price: 45
  },
  {
    key: "yearly-40",
    from: 101,
    to: 200,
    price: 40
  },
  {
    key: "yearly-35",
    from: 201,
    to: 400,
    price: 35
  },
  {
    key: "yearly-30",
    from: 401,
    to: 800,
    price: 30
  },
  {
    key: "yearly-25",
    from: 801,
    to: 3000,
    price: 25
  },
  {
    key: "yearly-10",
    from: 3001,
    to: "∞",
    price: 20
  }
];

const ASINZEN_API_MONTHLY: TableColumnsType<DataType> = [
  {
    key: "monthly-5",
    from: 1,
    to: 20,
    price: 5
  },
  {
    key: "monthly-4.5",
    from: 21,
    to: 100,
    price: 4.5
  },
  {
    key: "monthly-4",
    from: 101,
    to: 200,
    price: 4
  },
  {
    key: "monthly-3.5",
    from: 201,
    to: 400,
    price: 3.5
  },
  {
    key: "monthly-3",
    from: 401,
    to: 800,
    price: 3
  },
  {
    key: "monthly-2.5",
    from: 801,
    to: 3000,
    price: 2.5
  },
  {
    key: "monthly-2",
    from: 3001,
    to: "∞",
    price: 2
  }
];

const childColumns: TableColumnsType<DataType> = [
  {
    title: "From",
    dataIndex: "from",
    key: "from"
  },
  {
    title: "To",
    dataIndex: "to",
    key: "to"
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    render: value => `$${value}`
  }
];

const columns: TableColumnsType<DataType> = [
  {
    title: "Tiered quantity discounts",
    children: childColumns
  }
];

const PRICE_TABLES = {
  ASINZEN_API_MONTHLY,
  ASINZEN_API_YEARLY
};

const defaultAvailablePlugins = [
  {
    _id: "annually",
    intervalLables: "Annually",
    interval: "year",
    content:
      "Each token package contains 1,200,000 tokens. The token is reset on every new billing cycle, i.e un-used tokens expire at the end of the billing cycle.",
    code: "ASINZEN_API_YEARLY"
  },
  {
    _id: "monthly",
    intervalLables: "Monthly",
    interval: "month",
    content:
      "Each token package contains 100,000 tokens. The token is reset on every new billing cycle, i.e un-used tokens expire at the end of billing cycle.",
    code: "ASINZEN_API_MONTHLY"
  }
];

const AZApiPlanSubscription = ({
  planState,
  plugins,
  intervalLables,
  openRenewConfirmation,
  openStripePortal,
  openCheckoutUrl
}) => {
  const [states, setStates] = useState({
    availablePlugins: [],
    selectedPlan: [],
    subscribed: ""
  });

  useEffect(() => {
    getCurrentPluginsAddedRequest();
  }, []);

  const { pluginsAdded } = planState;

  useEffect(() => {
    let currentAvailable = plugins.map(item => {
      const pluginAdded = pluginsAdded.find(
        added => added.pluginId && added.pluginId._id === item._id
      );

      if (pluginAdded) {
        item.added = true;
        item.canceled = pluginAdded.isCancel;
        item.stripeSubId = pluginAdded.stripeSubId;
        item.nextSubscribeAt = moment(pluginAdded.createdAt)
          .local()
          .add(1, pluginAdded.pluginId.interval)
          .format("MMMM DD, YYYY");
      } else {
        item.added = false;
      }

      return item;
    });

    const selectedCurrent = currentAvailable.filter(item => item.added);
    const subscribedCurrent = selectedCurrent.length > 0 && !selectedCurrent[0].canceled;

    if (selectedCurrent.length > 0) {
      currentAvailable =
        currentAvailable.filter(item => item.added && item.interval.toLowerCase() === "year")
          .length > 0
          ? currentAvailable.filter(item => item.added && item.interval.toLowerCase() === "year")
          : currentAvailable.filter(item => item.added && item.interval.toLowerCase() === "month");
    }

    setStates({
      availablePlugins: currentAvailable,
      selectedPlan: selectedCurrent,
      subscribed: subscribedCurrent
    });
  }, [pluginsAdded]);

  return (
    states.availablePlugins.length > 0 && (
      <CustomPricingTable>
        <AzApiWrapper container>
          <Space direction="vertical" size="large" style={{ width: "100%", textAlign: "center" }}>
            <Typography.Text type="warning" italic style={{ fontSize: 14, fontWeight: "bold" }}>Important Note: asinzen tokens do not grant access to AZInsight or ScanEZ.</Typography.Text>
            {states.selectedPlan.length > 0 &&
              (states.subscribed ? (
                <div className="plan-item">
                  <div>Your subscription is active and will auto-renew.</div>
                  <div>
                    If you wish to cancel auto-renew on your subscription, please{" "}
                    <Button
                      type="link"
                      onClick={e => {
                        openStripePortal();
                      }}
                      style={{ padding: 0 }}
                    >
                      click here.
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="plan-item">
                  <div>
                    Your subscription is active until{" "}
                    <strong>{states.selectedPlan[0].nextSubscribeAt}</strong> and will{" "}
                    <strong>NOT</strong> auto-renew.
                  </div>
                  <div>
                    If you wish to automatically renew your subscription, please{" "}
                    <Button
                      type="link"
                      onClick={e =>
                        openRenewConfirmation(e, {
                          subId: states.selectedPlan[0].stripeSubId,
                          cancel: false
                        })
                      }
                      style={{ padding: 0 }}
                    >
                      click here.
                    </Button>
                  </div>
                </div>
              ))}
            <div className="pricing-table plugin-list">
              {states.availablePlugins.reverse().map(item => {
                const renderPricing = (
                  <div key={item._id} className={`pricing-plan ${item.added && "is-active"}`}>
                    <div className="plan-header">
                      Token Package Subscription Plans {intervalLables[item.interval.toLowerCase()]}
                    </div>
                    <div className="plan-items">
                      {item.content ? (
                        <div className="plan-item plan-content">{item.content}</div>
                      ) : null}
                      <div className="plan-item">
                        <Table
                          columns={columns}
                          dataSource={PRICE_TABLES[item.code]}
                          bordered
                          size="small"
                          pagination={false}
                        />
                      </div>
                    </div>
                    <div className="plan-footer">
                      {!item.added ? (
                        <button
                          className="button is-info is-fullwidth"
                          onClick={() => openCheckoutUrl(item, true)}
                        >
                          Purchase Subscription
                        </button>
                      ) : (
                        <button
                          className="button is-info is-fullwidth"
                          onClick={e =>
                            item.canceled
                              ? openRenewConfirmation(e, {
                                subId: item.stripeSubId,
                                cancel: false
                              })
                              : openStripePortal()
                          }
                        >
                          {item.canceled ? "Enable Auto Renew" : "Cancel"} Subscription
                        </button>
                      )}
                    </div>
                  </div>
                );
                return item.added ? (
                  <Badge.Ribbon
                    text={<strong style={{ color: "white" }}>Subscribed</strong>}
                    color="blue"
                    placement="start"
                    style={{ top: -10 }}
                  >
                    {renderPricing}
                  </Badge.Ribbon>
                ) : (
                  renderPricing
                );
              })}
            </div>
          </Space>
          <Space align="center" direction="vertical" style={{ marginTop: 10, width: "100%" }}>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/VbIPKQBAG9Y?si=9RsGs1Tu4jnoG3fk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </Space>
        </AzApiWrapper>
      </CustomPricingTable>
    )
  );
};

AZApiPlanSubscription.propTypes = {
  planState: PropTypes.shape(planPropTypes),
  plugins: PropTypes.array,
  intervalLables: PropTypes.object,
  openRenewConfirmation: PropTypes.func,
  openStripePortal: PropTypes.func,
  openCheckoutUrl: PropTypes.func
};

AZApiPlanSubscription.defaultProps = {
  planState: planInitState,
  plugins: null,
  intervalLables: null,
  openRenewConfirmation: null,
  openStripePortal: null,
  openCheckoutUrl: null
};

const withConnect = connectAutoDispatch(
  ({ userState, orderState, planState, stripeState }) => ({
    userState,
    planState
  }),
  {
    getCurrentPluginsAddedRequest
  }
);

export default compose(withRouter, withConnect)(AZApiPlanSubscription);
